<template>
  <v-card
    v-if="product"
    class="pa-0"
    rounded
    outlined
    elevation="24"
  >
    <template #progress>
      <v-progress-linear
        color="dark"
        height="10"
        indeterminate
      />
    </template>
    <v-carousel
      :continuous="true"
      :show-arrows="true"
      hide-delimiter-background
      :show-arrows-on-hover="true"
      delimiter-icon="mdi-minus"
      :style="{ height: carouselHeight }"
    >
      <v-carousel-item
        v-for="(image, i) in images"
        :key="'image-' + i"
        :style="{ height: carouselHeight }"
      >
        <v-img
          :src="image"
          max-width="100%"
          width="100%"
          contain
          :height="carouselHeight"
          :aspect-ratio="16/9"
          @click="detailPage"
        />
      </v-carousel-item>
    </v-carousel>
    <v-card-title class="pb-1">
      <template v-if="product.month">
        [한달]
      </template>
      {{ product.productName }}
    </v-card-title>
    <v-card-text>
      <div class="d-flex my-1 text-subtitle-1">
        <template v-if="!product.totalStock || product.totalStock === 0">
          <span>
            <span class="red--text">품절</span>
          </span>
        </template>
        <template v-else>
          <span v-if="product.valid === 'wait'">
            신청대기 <span class="text-caption blue-grey--text"> {{ parseDate(product.startDate) }}부터</span>
          </span>
          <span v-else-if="product.valid === 'sale'" class="success--text">
            신청가능 <span class="text-caption blue-grey--text"> {{ parseDate(product.endDate) }}까지</span>
          </span>
          <span v-else-if="product.valid === 'end'" class="red--text">
            신청종료 <span class="text-caption blue-grey--text"> {{ parseDate(product.endDate) }}까지</span>
          </span>
        </template>
        <v-spacer />
        <span>
          {{ product.productPrice ? '￦ ' + product.productPrice.toLocaleString() : '' }}
        </span>
      </div>
      <div class="d-flex my-1 text-caption blue-grey--text">
        <v-spacer />
        <template v-if="product.totalStock != null && product.totalStock !== 0">
          남은수량 {{ product.totalStock.toLocaleString() }}개
        </template>
        <template v-else>
          품절
        </template>
      </div>
      <div class="d-flex my-1 text-caption blue-grey--text">
        <v-spacer />
        <span>
          배송비 {{ deliveryFee }}
        </span>
      </div>
    </v-card-text>
    <v-card-actions :class="{ 'flex-wrap': mobile }">
      <template v-if="product.myAssigns && product.myAssigns.length > 0 && mobile">
        <div>
          <v-chip
            v-for="item in product.myAssigns"
            :key="`my-assign-${item.idx}`"
            label
            :small="!mobile"
            :x-small="mobile"
            class="mr-1"
          >
            {{ item.alias ? item.alias.username : item.users ? item.users.username : '' }}
          </v-chip>
        </div>
      </template>
      <v-btn
        class="px-5 mr-2"
        :width="mobile ? '100%' : ''"
        @click="detailPage"
      >
        신청하기
      </v-btn>
      <template v-if="product.myAssigns && product.myAssigns.length > 0 && !mobile">
        <v-chip
          v-for="item in product.myAssigns"
          :key="`my-assign-${item.idx}`"
          label
          :small="!mobile"
          :x-small="mobile"
          class="mr-1"
        >
          {{ item.alias ? item.alias.username : item.users ? item.users.username : '' }}
        </v-chip>
      </template>
    </v-card-actions>
  </v-card>
</template>

<script>
  export default {
    name: 'ProductCard',
    props: {
      product: {},
    },
    data () {
      return {
        carouselHeight: 'initial',
      }
    },
    computed: {
      mobile () {
        return this.$isMobile
      },
      images () {
        const imageList = []
        if (this.product) {
          if (this.product.productPhoto1) {
            imageList.push(this.product.productPhoto1)
          }
          if (this.product.productPhoto2) {
            imageList.push(this.product.productPhoto2)
          }
          if (this.product.productPhoto3) {
            imageList.push(this.product.productPhoto3)
          }
          if (this.product.productPhoto4) {
            imageList.push(this.product.productPhoto4)
          }
          if (this.product.productPhoto5) {
            imageList.push(this.product.productPhoto5)
          }
          if (!this.product.productPhoto1 && !this.product.productPhoto2 && !this.product.productPhoto3 && !this.product.productPhoto4 && !this.product.productPhoto5) {
            imageList.push(require('@/assets/images/no-image.jpg'))
          }
        }
        return imageList
      },
      deliveryFee () {
        const fee = this.product.deliveryFee
        const criteria = this.product.deliveryFeeCriteria
        let delivery
        if (!fee) {
          delivery = '무료배송'
        } else {
          delivery = fee.toLocaleString() + '원'
          if (criteria) {
            delivery += ' (' + criteria.toLocaleString() + '원 이상 구매 시 무료' + ')'
          }
        }
        return delivery
      },
    },
    methods: {
      detailPage () {
        const url = `/product/detail/${this.product.idx}`
        this.$router.push(url)
      },
      parseDate (date) {
        if (!isNaN(date)) {
          const d = new Date()
          d.setTime(date)
          return this.$moment(d).format('YY년 MM월 DD일')
        } else {
          return date
        }
      },
    },
  }
</script>

<style scoped lang="scss">
  .v-card::v-deep {
    .v-responsive.v-carousel__item {
      height: inherit !important;
    }
  }
</style>
