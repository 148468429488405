<template>
  <v-row class="mt-10">
    <v-col cols="3">
      <v-autocomplete
        v-if="parseCompany && parseCompany.length > 0"
        v-model="company.idx"
        :items="parseCompany"
        :search-input.sync="companyKeyword"
        label="Store"
        dense
        hide-details
        placeholder="업체 검색"
        no-data-text="검색된 업체가 없습니다."
      />
    </v-col>
    <v-col cols="3">
      <v-select
        v-model="searchField"
        :items="searchFields"
        label="Field"
        dense
        hide-details
        placeholder="조건"
      />
    </v-col>
    <v-col cols="6">
      <v-text-field
        v-model="keyword"
        label="Keyword"
        placeholder="검색어를 입력해주세요."
        dense
        hide-details
        append-icon="mdi-magnify"
        @keydown="enterSearch"
        @click:append="search"
      />
    </v-col>
  </v-row>
</template>

<script>
  export default {
    name: 'ProductSearch',
    props: [
      'companies',
    ],
    data () {
      return {
        status: this.$route.query.status ? this.$route.query.status : '',
        type: this.$route.query.type ? this.$route.query.type : '',
        keyword: this.$route.query.keyword ? this.$route.query.keyword : '',
        searchField: this.$route.query.searchField ? this.$route.query.searchField : null,
        searchFields: [
          {
            text: '전체',
            value: null,
          },
          {
            text: '상품명',
            value: 'productName',
          },
          {
            text: '신청대기',
            value: 'wait',
          },
          {
            text: '신청가능',
            value: 'pending',
          },
          {
            text: '신청종료',
            value: 'end',
          },
        ],
        company: {
          idx: this.$route.query.company ? parseInt(this.$route.query.company | String) : null,
        },
        companyKeyword: null,
      }
    },
    computed: {
      parseCompany () {
        const array = []
        array.push({
          text: '전체',
          value: null,
        })
        for (const company of this.companies) {
          array.push(company)
        }
        return array
      },
    },
    methods: {
      search () {
        const data = {
          keyword: this.keyword ? this.keyword : '',
          searchField: this.searchField ? this.searchField : '',
          company: this.company.idx ? this.company.idx : '',
        }
        this.$router.push({
          path: '/product/1',
          query: data,
        }, () => {
          this.$emit('search', data)
        })
      },
      enterSearch () {
        if (event.keyCode === 13) {
          this.search()
        }
      },
    },
  }
</script>

<style scoped>

</style>
