<template>
  <v-row v-if="pagination && pagination.list.length > 0">
    <v-col v-for="product in pagination.list" :key="product.idx" cols="12" md="4">
      <ProductCard :product="product" />
    </v-col>
  </v-row>
</template>

<script>
  import ProductCard from '../../components/product/ProductCard'
  export default {
    name: 'ProductContent',
    components: { ProductCard },
    props: {
      pagination: {},
      notices: {},
    },
    data () {
      return {
      }
    },
  }
</script>

<style scoped>

</style>
