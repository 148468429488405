<template>
  <v-lazy
    :options="{
      threshold: .5
    }"
  >
    <v-container>
      <ProductHeader :pagination="pagination" @refresh="getPagination" />
      <ProductSearch :companies="companies" @search="getPagination" />
      <v-sheet class="px-3 mt-5 mb-3 mb-md-0" elevation="24" rounded>
        <ProductContent :pagination="pagination" />
        <v-row>
          <v-col cols="12" md="8" offset-md="2">
            <Pagination ref="pagination" :pagination="pagination" :show-num="7" :base-url="'/product/'" :query="$route.query" />
          </v-col>
        </v-row>
      </v-sheet>
    </v-container>
  </v-lazy>
</template>

<script>
  import ProductHeader from './Header'
  import ProductSearch from './Search'
  import ProductContent from './Content'
  import Pagination from '../../components/core/Pagination'
  export default {
    name: 'Product',
    components: {
      Pagination,
      ProductContent,
      ProductSearch,
      ProductHeader,
    },
    data () {
      return {
        search: {
          keyword: '',
        },
        pagination: {
          list: [],
          pageNum: this.pageNum ? this.pageNum : 1,
        },
        pageDataSize: '9',
        isShowWrite: false,
        companies: [],
        paginationKey: 0,
      }
    },
    computed: {
      mobile () {
        return this.$isMobile
      },
      pageNum () {
        return this.$route.params.pageNum
      },
      status () {
        return this.$route.query.status
      },
      type () {
        return this.$route.query.type
      },
      keyword () {
        return this.$route.query.keyword
      },
      startDate () {
        return this.$route.query.sdt
      },
      endDate () {
        return this.$route.query.edt
      },
      searchField () {
        return this.$route.query.searchField
      },
      company () {
        return this.$route.query.company
      },
    },
    watch: {
      pageNum () {
        this.getPagination()
      },
    },
    beforeMount () {
      this.getPagination()
      this.getCompanies()
    },
    methods: {
      closeDialog () {
        this.isShowWrite = false
        this.getPagination()
      },
      async getCompanies () {
        const url = '/company/company/items'
        await this.$axios.get(url)
          .then(res => {
            if (res && res.data) {
              this.companies = res.data
            }
          })
          .catch(err => {
            console.log(err)
          })
      },
      getPagination (payload) {
        const data = {
          pageNum: this.pageNum,
          pageDataSize: this.pageDataSize,
          keyword: payload && payload.keyword ? payload.keyword : this.keyword,
          status: payload && payload.status ? payload.status : this.status,
          type: payload && payload.type ? payload.type : this.type,
          searchField: payload && payload.searchField ? payload.searchField : this.searchField,
          company: payload && payload.company ? payload.company : this.company,
          sdt: payload && payload.startDate ? payload.startDate : this.getQueryDate(this.startDate, 'start'),
          edt: payload && payload.endDate ? payload.endDate : this.getQueryDate(this.endDate, 'end'),
        }
        this.$axios.get('product/products', {
          params: data,
        })
          .then(res => {
            if (res && res.data) {
              this.pagination = res.data
            }
          })
          .catch(err => {
            console.log(err)
          })
      },
      getQueryDate (date, type) {
        if (date) {
          let time
          if (isNaN(date)) {
            const year = date.split('-')[0]
            const month = date.split('-')[1]
            const day = date.split('-')[2]
            let d
            if (type === 'start') {
              d = new Date(year, month - 1, day, 0, 0, 0, 0)
            } else {
              d = new Date(year, month - 1, day, 23, 59, 59, 999)
            }
            time = d.getTime()
          } else {
            const d = new Date()
            time = d.setTime(date)
          }
          return time
        } else {
          return null
        }
      },
    },
  }
</script>

<style scoped>

</style>
