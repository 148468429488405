import { render, staticRenderFns } from "./Content.vue?vue&type=template&id=a325b7a2&scoped=true&"
import script from "./Content.vue?vue&type=script&lang=js&"
export * from "./Content.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a325b7a2",
  null
  
)

export default component.exports